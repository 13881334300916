module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_76rdrnlfmsygog7lf6mgvxu2w4/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_o6ddo756jznkd6flqtv5kt7mna/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.4.0_et2mzcserthdm76f3632pwirzq/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BVQYNV68JR"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.2_o4scbtliisanygemawej7x2d6i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
